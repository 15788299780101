/* ----- Style ----- */
import '../stylesheets/tarteaucitron'

/* ----- TAC ----- */
const { tarteaucitron } = require('exports-loader?exports=tarteaucitron!tarteaucitronjs/tarteaucitron')
global.tarteaucitron = tarteaucitron

/* ----- Functions ----- */
tarteaucitron.fixSelfXSS = function(html) { // Opsone fix for strict mode
  return html.toString().replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&quot;').replace(/'/g, '&#039;')
}

tarteaucitron.getLanguage = function() {
  return document.documentElement.lang.substring(0, 2)
}

/* ----- Locales ----- */
switch (tarteaucitron.getLanguage()) {
  case 'fr':
    require('tarteaucitronjs/lang/tarteaucitron.fr.js')
    break
  default:
    require('tarteaucitronjs/lang/tarteaucitron.en.js')
}

const customText = {
  "en": {
    "middleBarHead": "Manage my cookies",
    "acceptAll": "Accept all",
    "allowAll": "Allow all",
    "denyAll": "Deny all"
  },
  "fr": {
    "middleBarHead": "Gérer mes cookies",
    "acceptAll": "Tout accepter",
    "allowAll": "Tout autoriser",
    "denyAll": "Tout refuser"
  },
}

let tarteaucitronCustomText = tarteaucitron.getLanguage() in customText ? customText[tarteaucitron.getLanguage()] : ''
tarteaucitron.lang = { ...tarteaucitron.lang, ...tarteaucitronCustomText }

/* ----- Services ----- */
require('tarteaucitronjs/tarteaucitron.services.js')

tarteaucitron.services.mtcaptcha['needConsent'] = false
tarteaucitron.services.mtcaptcha['js'] = function() {
  window.mtcaptchaConfig = {
    sitekey: tarteaucitron.user.mtcaptchaSitekey,
    lang: tarteaucitron.getLanguage()
  }

  tarteaucitron.addScript('https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js')
  tarteaucitron.addScript('https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js')
}
tarteaucitron.services.mtcaptcha['fallback'] = function() {
  'use strict'
  var id = 'mtcaptcha'
  tarteaucitron.fallback(['mtcaptcha'], tarteaucitron.engage(id))
}

tarteaucitron.init({
  privacyUrl: '', /* Privacy policy url */
  bodyPosition: 'bottom', /* or top to bring it as first element for accessibility */
  hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
  cookieName: 'tarteaucitron', /* Cookie name */
  orientation: 'middle', /* Banner position (top - bottom) */
  groupServices: false, /* Group services by category */
  serviceDefaultState: 'wait', /* Default state (true - wait - false) */
  showAlertSmall: false, /* Show the small banner on bottom right */
  cookieslist: false, /* Show the cookie list */
  closePopup: false, /* Show a close X on the banner */
  showIcon: false, /* Show cookie icon to manage cookies */
  //iconSrc: '', /* Optionnal: URL or base64 encoded image */
  iconPosition: 'BottomRight', /* BottomRight, BottomLeft, TopRight and TopLeft */
  adblocker: false, /* Show a Warning if an adblocker is detected */
  denyAllCta : true, /* Show the deny all button */
  acceptAllCta : true, /* Show the accept all button when highPrivacy on */
  highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */
  handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */
  removeCredit: true, /* Remove credit link */
  moreInfoLink: true, /* Show more info link */
  useExternalCss: true, /* If false, the tarteaucitron.css file will be loaded */
  useExternalJs: true, /* If false, the tarteaucitron.js file will be loaded */
  //cookieDomain: ".my-multisite-domaine.fr", /* Shared cookie for multisite */
  readmoreLink: '', /* Change the default readmore link */
  mandatory: true, /* Show a message about mandatory cookies */
  mandatoryCta: false, /* Show the disabled accept button when mandatory on */
})

document.addEventListener('turbo:render', () => {
  tarteaucitron.added = {}
  tarteaucitron.initEvents.loadEvent(false)
  tarteaucitron.job.forEach((e) => { tarteaucitron.job.push(e) })
})

document.addEventListener('turbo:load', () => {
  tarteaucitron.addClickEventToId('tarteaucitronManagerCustom', () => {
    tarteaucitron.userInterface.openPanel()
  })
})

/* ----- Add services to TAC ----- */
tarteaucitron.user.mtcaptchaSitekey = 'MTPublic-uf1wkw80k'

tarteaucitron.job = []
tarteaucitron.job.push('mtcaptcha')
tarteaucitron.job.push('vimeo')
tarteaucitron.job.push('youtube')
